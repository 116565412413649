.App {
    text-align: center;
    background-image: url('/src/resources/trianglify-lowres.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin + 150%);
}

#logo {
    max-height: 12vmax;
    height: 300px;
    margin-top: 2vmin;
    margin-bottom: 5vmin;
    pointer-events: none;
}

.Sound-buttons {
    align-self: flex-end;
    margin-right: 0%;
    height: max(100px, 5vmin);
    max-height: 10vmax;
    width: max(200px, 5vmin);
    max-width: 25vmax;
}

.soundIcon {
    max-width: 50%;
}

.Center-container {
    width: 50%;
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
}


/* These medias help position the sound buttons nicely */

@media all and (max-width: 2000px) {
    .Center-container {
        width: 60%;
    }
}

@media all and (max-width: 1500px) {
    .Center-container {
        width: 70%;
    }
}

@media all and (max-width: 1000px) {
    .Center-container {
        width: 80%;
    }
}

@media all and (max-width: 500px) {
    .Center-container {
        width: 90%;
    }
}