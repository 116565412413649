.Text-container {
    display: flex;
    flex-flow: row wrap;
    color: #000000;
    direction: rtl;
}

@media (prefers-reduced-motion: no-preference) {
    .Text-idle {
        animation: Text-idle 0.8s ease-in-out infinite alternate;
        -webkit-animation: Text-idle 0.8s ease-in-out infinite alternate;
    }
    .Text-insert {
        animation: Text-insert 0.2s ease-out normal;
        -webkit-animation: Text-insert 0.2s ease-out normal;
    }
}

@keyframes Text-idle {
    from {
        transform: scale(2);
        -webkit-transform: scale(2);
        -moz-transform: scale(2);
        -ms-transform: scale(2);
        -o-transform: scale(2);
    }
    to {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@keyframes Text-insert {
    0% {
        transform: scale(5) rotate(10deg) translate(-0vw, 0vh);
        -webkit-transform: scale(5) rotate(10deg) translate(-0vw, 0vh);
        -moz-transform: scale(5) rotate(10deg) translate(-0vw, 0vh);
        -ms-transform: scale(5) rotate(10deg) translate(-0vw, 0vh);
        -o-transform: scale(5) rotate(10deg) translate(-0vw, 0vh);
    }
    50% {
        transform: scale(0.9) rotate(-20deg);
        -webkit-transform: scale(0.9) rotate(-20deg);
        -moz-transform: scale(0.9) rotate(-20deg);
        -ms-transform: scale(0.9) rotate(-20deg);
        -o-transform: scale(0.9) rotate(-20deg);
    }
    100% {
        transform: scale(1.0) rotate(0deg);
        -webkit-transform: scale(1.0) rotate(0deg);
        -moz-transform: scale(1.0) rotate(0deg);
        -ms-transform: scale(1.0) rotate(0deg);
        -o-transform: scale(1.0) rotate(0deg);
    }
}